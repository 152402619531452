import * as React from "react";
import styled from "styled-components";

function Footer() {
  return <Footer1>©　2023　なかと経営戦略事務所</Footer1>;
}

const Footer1 = styled.div`
  color: var(--text_white, #fff);
  letter-spacing: 0.84px;
  white-space: nowrap;
  align-items: center;
  align-content: flex-end;
  flex-wrap: wrap;
  text-align: center;
  background-color: var(--text_green, #2e4832);
  padding: 32px 60px 15px;
  font: 400 12px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

export default Footer;
