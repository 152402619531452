import './App.css';
import Header from './Header'
import Top from './Top'
import {
  NewsCollection,
  ProfileCollection
 } from './ui-components';
import Plofile from './Plofile'
import Footer from'./Footer'

function App() {
  return (
    <div className="App">
      <Header />
      <Top />
      <div className="News"><NewsCollection /></div>
      <div className="Profile">
        <div className="ProfileContent">
        <h2>プロフィール</h2>
        <ProfileCollection />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
