import * as React from "react";
import styled from "styled-components";
import webpImage from "./img/aaron-burden-o-ubWHV29Uk-unsplash.webp";  // WebP image
import jpgImage from "./img/aaron-burden-o-ubWHV29Uk-unsplash.jpg";  // JPG image

function Top() {
  return (
    <Div webpImage={webpImage} jpgImage={jpgImage} />
  );
}

const Div = styled.div`
  background-image: url(${props => (props.webpSupported ? props.webpImage : props.jpgImage)});
  background-size: cover;
  align-self: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  inset: 0;
  height: 56%;
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
`;

export default Top;
