import * as React from "react";
import styled from "styled-components";

function Header() {
  return <Header1>なかと経営戦略事務所</Header1>;
}

const Header1 = styled.div`
  color: var(--text_green, #2e4832);
  letter-spacing: 2.5px;
  white-space: nowrap;
  align-items: start;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  justify-content: center;
  padding: 13px 60px;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 1280px) {
    white-space: initial;
    padding: 0 20px 0 29px;
    font: 36px;
  }
`;

export default Header;
