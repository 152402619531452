import * as React from "react";
import styled from "styled-components";

function Plofile() {
  return (
    <Div>
      <Div2>プロフィール</Div2>
      <Div3>
        <Div4>
          <Div5>名前</Div5>
          <Div6>所属団体</Div6>
          <Div7>資格</Div7>
          <Div8>得意分野</Div8>
          <Div9>経歴</Div9>
          <Div10>
            2010年
            <br />
            ​2013年
            <br />
            <br />
            ​2016年
            <br />
            ​2023年
          </Div10>
        </Div4>
        <Div11>
          <Div12>仲戸 一翔（なかと かずは）</Div12>
          <Div13>山口県中小企業診断協会</Div13>
          <Div14>中小企業診断士（登録番号426066）、応用情報技術者</Div14>
          <Div15>データ活用の体制づくり、生産性診断・向上、資金繰り対策</Div15>
          <Div16>
            防府高校普通科卒業
            <br />
            関西大学政策創造学部中退
            <br />
            精密測定機器メーカー入社
            <br />
            ベンチャー建設業入社
            <br />
            中小企業診断士登録​
            <br />
            なかと経営戦略事務所設立
          </Div16>
        </Div11>
      </Div3>
      <Div17>
        <Div18>
          <Div19>屋号</Div19>
          <Div20>所在地</Div20>
        </Div18>
        <Div21>
          <Div22>なかと経営戦略事務所</Div22>
          <Div23>〒747-0047 山口県防府市新橋町</Div23>
        </Div21>
      </Div17>
      <Div24>
        <Div25>
          <Column>
            <Div26>
              <Div27>TEL</Div27>
              <Div28>Email</Div28>
              <Div29>URL</Div29>
            </Div26>
          </Column>
          <Column2>
            <Div30>
              <Div31>080-6438-5726</Div31>
              <Div32>k.nakato@nakato-ms.com</Div32>
              <Div33>https://nakato-ms.com</Div33>
            </Div30>
          </Column2>
        </Div25>
      </Div24>
    </Div>
  );
}

const Div = styled.div`
  align-items: flex-start;
  border-radius: 52px;
  background-color: #c4f5c4;
  display: flex;
  flex-direction: column;
  padding: 50px 80px 50px 64px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Div2 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 3.36px;
  align-self: start;
  margin-top: 14px;
  white-space: nowrap;
  font: 700 48px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    white-space: initial;
  }
`;

const Div3 = styled.div`
  align-self: center;
  display: flex;
  align-items: start;
  gap: 20px;
  margin: 40px 0 0 -95px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div4 = styled.div`
  display: flex;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div5 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div6 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div7 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div8 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div9 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  margin-top: 47px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

const Div10 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  margin-top: 27px;
  font: 500 24px/60px Yu Gothic, sans-serif;
`;

const Div11 = styled.div`
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div12 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  white-space: nowrap;
  font: 500 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div13 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 500 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div14 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 500 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div15 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 500 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div16 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  margin-top: 96px;
  font: 500 24px/60px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div17 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 60px;
  gap: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const Div18 = styled.div`
  display: flex;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div19 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div20 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div21 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div22 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  white-space: nowrap;
  font: 500 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div23 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 500 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div24 = styled.div`
  align-self: start;
  width: 430px;
  max-width: 100%;
  margin: 16px 0 14px;
`;

const Div25 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 18%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div26 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 18px;
  }
`;

const Div27 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div28 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div29 = styled.div`
  color: var(--text_black, #1a1a1a);
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 700 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 82%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div30 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 18px;
  }
`;

const Div31 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  white-space: nowrap;
  font: 500 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div32 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 500 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div33 = styled.div`
  color: #000;
  letter-spacing: 2.4px;
  margin-top: 16px;
  white-space: nowrap;
  font: 500 24px Yu Gothic, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default Plofile;