/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text, View, useBreakpointValue } from "@aws-amplify/ui-react";
export default function Profile(props) {
  const { profile, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        "\u9805\u76EE": {},
        "\u5185\u5BB9": {},
        "Frame 440": {},
        Profile: {},
      },
      variantValues: { breakpoint: "xl" },
    },
    {
      overrides: {
        "\u9805\u76EE": { fontSize: "16px", lineHeight: "24px" },
        "\u5185\u5BB9": {
          fontSize: "16px",
          lineHeight: "24px",
          width: "300px",
          top: "34px",
          left: "10px",
        },
        "Frame 440": { width: "320px" },
        Profile: {},
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    xl: "xl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="10px 10px 10px 10px"
      backgroundColor="rgba(199,239,202,1)"
      display="flex"
      {...getOverrideProps(overrides, "Profile")}
      {...rest}
    >
      <View
        width="1220px"
        height="58px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 440")}
      >
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="36px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="300px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="10px"
          left="10px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={profile?.item}
          {...getOverrideProps(overrides, "\u9805\u76EE")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="36px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="890px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="10px"
          left="320px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={profile?.content}
          {...getOverrideProps(overrides, "\u5185\u5BB9")}
        ></Text>
      </View>
    </Flex>
  );
}
